/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://hlmuirt6szhvvaxcrxvd24vkty.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-4ykvjyx575a2xo6w2xoom7e63e",
    "aws_cognito_identity_pool_id": "us-east-2:c25ea578-056c-4142-bc4d-3764218f27aa",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_VKrbNI5a9",
    "aws_user_pools_web_client_id": "41oghp4r2e56qmmmr279sskv2g",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 6,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
